import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function SelectConsumerLoan() {
    return (
        <div className="kioskContainer">
            <a href="/#/">
                <div className="menuMenuButton">
                    <div>
                        <FontAwesomeIcon icon="caret-left" />
                    </div>
                    <div>
                        <div>Main Menu</div>
                    </div>
                </div>
            </a>
            <div id="logoRight"></div>
            <div className="subContent">
                <div className="topicLabel">Consumer Loans</div>
                <div class="optionsPageHeader">
                    <h2>How can we help you today?</h2>
                </div>
                <br></br>
                <br></br>
                <div className="row">
                    <div className="col-3">&nbsp;</div>
                    <div className="col-6">
                        <a href="/#/consumerApp/personal">
                            <div className="bigButton">
                                <FontAwesomeIcon icon="user" />
                                <br />
                                Personal Loan
                            </div>
                        </a>
                    </div>
                    <div className="col-3">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-3">&nbsp;</div>
                    <div className="col-6">
                        <a href="/#/autoApp/personal">
                            <div className="bigButton">
                                <FontAwesomeIcon icon="car" />
                                <br />
                                Auto Loan
                            </div>
                        </a>
                    </div>
                    <div className="col-3">&nbsp;</div>
                </div>
            </div>
        </div>
    );
}
