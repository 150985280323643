import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function AutoAppStep3() {
    return (
        <div className="kioskContainer">
            <a href="/#/">
                <div className="menuMenuButton">
                    <div>
                        <FontAwesomeIcon icon="caret-left" />
                    </div>
                    <div>
                        <div>Main Menu</div>
                    </div>
                </div>
            </a>
            <div id="logoRight"></div>
            <div className="subContent">
                <div className="consumerAppStepHeader">Step 3: Finish Application</div>
                <h2 className="smallerHeadline">Flexible Auto Financing</h2>
                <div className="formContainer">
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="checking_account_number" placeholder="Checking Acct. #" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm" type="text" name="confirm_checking_account_number" placeholder="Confirm Checking Acct. #" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="bank_routing_number" placeholder="Bank Routing #" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm" type="text" name="confirm_bank_routing_number" placeholder="Confirm Bank Routing #" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 left">
                            <br />
                            <label>Privacy Notice and Terms of Use:</label>
                        </div>
                        <div className="col-6 left">
                            <br />
                            <table className="disclaimerRow">
                                <tr>
                                    <td valign="top">
                                        <input className="disclaimerCheckbox" type="checkbox" id="privacyNoticeAndTerms" name="privacyNoticeAndTerms" />
                                    </td>
                                    <td valign="top" className="disclaimerContent">
                                        <label for="privacyNoticeAndTerms">
                                            I accept the <a href="#">Privacy Notice</a> and <a href="#">Terms of Use</a>.
                                        </label>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm noTopMargin" type="text" name="password" placeholder="Password" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm noTopMargin" type="text" name="confirm_password" placeholder="Confirm Password" />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 left">
                            <br />
                            <label>Application Acknowledgement & Disclosures:</label>
                        </div>
                        <div className="col-6 left">
                            <br />
                            <label>
                                <b>To accept this acknowledgement, you must view it first.</b>
                                <br />
                                <br />
                                <div className="row">
                                    <div className="col-6 left">
                                        <button className="btn btn-sm btn-success">View Acknowledgement</button>
                                    </div>
                                    <div className="col-6">
                                        <table className="disclaimerRow">
                                            <tr>
                                                <td valign="top">
                                                    &nbsp;&nbsp;&nbsp;
                                                    <input className="disclaimerCheckbox" type="checkbox" id="acceptAcknowledgement" name="acceptAcknowledgement" disabled />
                                                </td>
                                                <td valign="top" className="disclaimerContent">
                                                    <label className="smallerLabel" for="acceptAcknowledgement" disabled>
                                                        Accept
                                                    </label>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm noTopMargin" type="text" name="electronic_signature" placeholder="Electronic Signature" />
                        </div>
                        <div className="col-6 left">
                            I'm interested in receiving information on products and promotions from Liberty and its affiliates via:
                            <br />
                            <br />
                            <table className="disclaimerRow">
                                <tr>
                                    <td valign="top">
                                        <input className="disclaimerCheckbox" type="checkbox" id="privacyNoticeAndTerms" name="privacyNoticeAndTerms" />
                                    </td>
                                    <td valign="top" className="disclaimerContent">
                                        <label for="privacyNoticeAndTerms">Contact Email Optin Promotion</label>
                                    </td>
                                </tr>
                            </table>
                            I understand consent is not required to enter into any agreement with Liberty or its affiliates.
                        </div>
                    </div>

                    <br />
                    <a class="submitButton" href="/#/autoApp/confirmed">
                        FINISH APPLICATION
                        <FontAwesomeIcon icon="caret-right" />
                    </a>
                </div>
            </div>
        </div>
    );
}
