import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function AutoAppPersonal2() {
    return (
        <div className="kioskContainer">
            <a href="/#/">
                <div className="menuMenuButton">
                    <div>
                        <FontAwesomeIcon icon="caret-left" />
                    </div>
                    <div>
                        <div>Main Menu</div>
                    </div>
                </div>
            </a>
            <div id="logoRight"></div>
            <div className="subContent">
                <div className="consumerAppStepHeader">Step 1: Personal Information (2 of 2)</div>
                <h2 className="smallerHeadline">Flexible Auto Financing</h2>
                <div className="formContainer">
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="work_phone" placeholder="Work Phone" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm" type="text" name="home_phone" placeholder="Home Phone" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="street_address" placeholder="Street Address" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm" type="text" name="city" placeholder="City" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="state" placeholder="State" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm" type="text" name="zip" placeholder="ZIP" />
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-6">
                            <a class="backButton" href="/#/autoApp/personal">
                                PREV
                                <FontAwesomeIcon icon="caret-left" />
                            </a>
                        </div>
                        <div className="col-6">
                            <a class="submitButton" href="/#/autoApp/employment">
                                NEXT
                                <FontAwesomeIcon icon="caret-right" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
