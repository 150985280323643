import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function ConsumerAppConfirmed() {
  return (
    <div className="kioskContainer">
      <a href="/#/">
        <div className="menuMenuButton">
          <div>
            <FontAwesomeIcon icon="caret-left" />
          </div>
          <div>
            <div>Main Menu</div>
          </div>
        </div>
      </a>
      <div id="logoRight"></div>
      <div className="subContent">
        <div className="consumerAppStepHeader approved">Loan Approved</div>
        <h2 className="smallerHeadline">Congratulations!</h2>
        <br />
        <br />
        <h3>Your application has been pre-approved.</h3>
        <br /> <br />
        <div class="content">
          A Liberty representative will be reaching out to you shortly to
          discuss the details.
          <br />
          Thank you for choosing Liberty for your financial services needs.
        </div>
      </div>
    </div>
  );
}
