import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function ConsumerAppStep2() {
    return (
        <div className="kioskContainer">
            <a href="/#/">
                <div className="menuMenuButton">
                    <div>
                        <FontAwesomeIcon icon="caret-left" />
                    </div>
                    <div>
                        <div>Main Menu</div>
                    </div>
                </div>
            </a>
            <div id="logoRight"></div>
            <div className="subContent">
                <div className="consumerAppStepHeader">Step 2: Employment Information</div>
                <h2 className="smallerHeadline">Explore Your Loan Options</h2>
                <div className="formContainer">
                    <div className="row">
                        <div className="col-12">
                            <label>Are you an active duty member of the military, or the spouse or dependent of?</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <table className="disclaimerRow">
                                <tr>
                                    <td valign="top">
                                        <input className="disclaimerCheckbox" type="radio" id="noActiveMilitary" name="activeMilitary" value="no" />
                                    </td>
                                    <td valign="top" className="disclaimerContent">
                                        <label for="noActiveMilitary">No, not active military, dependent or spouse</label>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div className="col-6">
                            {" "}
                            <table className="disclaimerRow">
                                <tr>
                                    <td valign="top">
                                        <input className="disclaimerCheckbox" type="radio" id="activeMilitary" name="activeMilitary" value="yes" />
                                    </td>
                                    <td valign="top" className="disclaimerContent">
                                        <label for="activeMilitary">Yes, active military, dependent or spouse</label>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="employer_name" placeholder="Employer/Income Source" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm" type="text" name="employer_phone" placeholder="Employer Phone Number" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="monthly_income" placeholder="Gross Monthly Income ($)" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm" type="text" name="next_income_date" placeholder="Next Income Date" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="date_hired" placeholder="Date Hired" />
                        </div>
                        <div className="col-6 left">
                            <br />
                            <br />
                            <label>Pay Date Frequency</label>
                            <br />
                            <table className="disclaimerRow">
                                <tr>
                                    <td valign="top">
                                        <input className="disclaimerCheckbox" type="radio" id="payWeekly" name="payFrequency" value="weekly" />
                                    </td>
                                    <td valign="top" className="disclaimerContent">
                                        <label for="payWeekly">Weekly</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top">
                                        <input className="disclaimerCheckbox" type="radio" id="payBiweekly" name="payFrequency" value="biweekly" />
                                    </td>
                                    <td valign="top" className="disclaimerContent">
                                        <label for="payBiweekly">Biweekly (every other week)</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top">
                                        <input className="disclaimerCheckbox" type="radio" id="payTwiceMonthly" name="payFrequency" value="twicemonthly" />
                                    </td>
                                    <td valign="top" className="disclaimerContent">
                                        <label for="payTwiceMonthly">Twice A Month</label>
                                    </td>
                                </tr>
                                <tr>
                                    <td valign="top">
                                        <input className="disclaimerCheckbox" type="radio" id="payMonthly" name="payFrequency" value="monthly" />
                                    </td>
                                    <td valign="top" className="disclaimerContent">
                                        <label for="payMonthly">Monthly</label>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <table width="96%">
                        <tr>
                            <td>
                                <div className="spaceRight">
                                    <a class="backButton" href="/#/consumerApp/personal2">
                                        BACK
                                        <FontAwesomeIcon icon="caret-left" />
                                    </a>
                                </div>
                            </td>
                            <td>
                                <div className="spaceLeft">
                                    <a class="submitButton" href="/#/consumerApp/finish">
                                        NEXT
                                        <FontAwesomeIcon icon="caret-right" />
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    );
}
