import { React } from "react";
import { HashRouter, Route } from "react-router-dom";
import "./LibertyKiosk.css";
import Splash from "../Screens/Splash";
import BusinessApp from "../Screens/BusinessApp/BusinessApp";
import BusinessAppConfirmed from "../Screens/BusinessApp/BusinessAppConfirmed";
import SelectConsumerLoan from "../Screens/SelectConsumerLoan";
import AutoAppPersonal1 from "../Screens/AutoApp/AutoAppPersonal1";
import AutoAppPersonal2 from "../Screens/AutoApp/AutoAppPersonal2";
import AutoAppStep2 from "../Screens/AutoApp/AutoAppStep2";
import AutoAppStep3 from "../Screens/AutoApp/AutoAppStep3";
import AutoAppConfirmed from "../Screens/AutoApp/AutoAppConfirmed";
import ConsumerAppPersonal1 from "../Screens/ConsumerApp/ConsumerAppPersonal1";
import ConsumerAppPersonal2 from "../Screens/ConsumerApp/ConsumerAppPersonal2";
import ConsumerAppStep2 from "../Screens/ConsumerApp/ConsumerAppStep2";
import ConsumerAppStep3 from "../Screens/ConsumerApp/ConsumerAppStep3";
import ConsumerAppConfirmed from "../Screens/ConsumerApp/ConsumerAppConfirmed";
import HomeApp from "../Screens/HomeApp/HomeApp";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faBuilding, faHome, faCaretLeft, faCar, faCaretRight } from "@fortawesome/free-solid-svg-icons";

library.add(faUser, faBuilding, faHome, faCaretLeft, faCar, faCaretRight);

const LibertyKiosk = () => {
    return (
        <HashRouter>
            <div className="wrapper">
                <Route path="/">
                    <Header />
                    <Splash />
                    <Footer />
                </Route>
                <Route path="/selectConsumerLoan">
                    <Header />
                    <SelectConsumerLoan />
                    <Footer />
                </Route>
                <Route path="/autoApp/personal">
                    <Header />
                    <AutoAppPersonal1 />
                    <Footer />
                </Route>
                <Route path="/autoApp/personal2">
                    <Header />
                    <AutoAppPersonal2 />
                    <Footer />
                </Route>
                <Route path="/autoApp/employment">
                    <Header />
                    <AutoAppStep2 />
                    <Footer />
                </Route>
                <Route path="/autoApp/finish">
                    <Header />
                    <AutoAppStep3 />
                    <Footer />
                </Route>
                <Route path="/autoApp/confirmed">
                    <Header />
                    <AutoAppConfirmed />
                    <Footer />
                </Route>
                <Route path="/consumerApp/personal">
                    <Header />
                    <ConsumerAppPersonal1 />
                    <Footer />
                </Route>
                <Route path="/consumerApp/personal2">
                    <Header />
                    <ConsumerAppPersonal2 />
                    <Footer />
                </Route>
                <Route path="/consumerApp/employment">
                    <Header />
                    <ConsumerAppStep2 />
                    <Footer />
                </Route>
                <Route path="/consumerApp/finish">
                    <Header />
                    <ConsumerAppStep3 />
                    <Footer />
                </Route>
                <Route path="/consumerApp/confirmed">
                    <Header />
                    <ConsumerAppConfirmed />
                    <Footer />
                </Route>
                <Route path="/businessApp">
                    <Header />
                    <BusinessApp />
                    <Footer />
                </Route>
                <Route path="/businessApp/confirmed">
                    <Header />
                    <BusinessAppConfirmed />
                    <Footer />
                </Route>
                <Route path="/homeApp">
                    <Header />
                    <HomeApp />
                    <Footer />
                </Route>
            </div>
        </HashRouter>
    );
};

export default LibertyKiosk;
