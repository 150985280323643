import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function BusinessApp() {
    return (
        <div className="kioskContainer">
            <a href="/#/">
                <div className="menuMenuButton">
                    <div>
                        <FontAwesomeIcon icon="caret-left" />
                    </div>
                    <div>
                        <div>Main Menu</div>
                    </div>
                </div>
            </a>
            <div id="logoRight"></div>
            <div className="subContent">
                <div className="topicLabel">Business Loans</div>
                <div class="optionsPageHeader">
                    <h2>Let's Pre-Qualify You!</h2>
                </div>
                <br />

                <h3>Will not affect your credit score!</h3>
                <div className="formContainer">
                    <div class="row">
                        <div class="col-6">
                            <input className="loanForm" type="text" name="first_name" placeholder="First Name" />
                        </div>
                        <div class="col-6">
                            <input className="loanForm" type="text" name="last_name" placeholder="Last Name" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <input className="loanForm" type="text" name="birthdate" placeholder="Date of Birth" />
                        </div>
                        <div class="col-6">
                            <input className="loanForm" type="text" name="phone_number" placeholder="Phone Number" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <input className="loanForm" type="text" name="email_address" placeholder="Email Address" />
                        </div>
                        <div class="col-12">
                            <input className="loanForm" type="text" name="business_income" placeholder="Business Monthly Gross Revenue" />
                        </div>
                    </div>
                    <br />
                    <table className="disclaimerRow">
                        <tr>
                            <td valign="top">
                                <input className="disclaimerCheckbox" type="checkbox" id="readDisclaimers" />
                            </td>
                            <td valign="top" className="disclaimerContent">
                                <label for="readDisclaimers">By checking this box, you agree that you have read, understood, and consent to our Credit Authorization and Contact Authorization.</label>
                            </td>
                        </tr>
                    </table>
                    <table className="disclaimerRow">
                        <tr>
                            <td valign="top">
                                <input className="disclaimerCheckbox" type="checkbox" id="acceptMarketing" />
                            </td>
                            <td valign="top" className="disclaimerContent">
                                <label for="acceptMarketing">By checking this box you agree to receive marketing communications via automated telephone dialing systems, pre-recorded calls, text/SMS messages, and emails from Liberty and related third-parties at the phone number you provide, including landline and cellular numbers, even if the phone number is on a corporate, state, or national do not call list. Message and data rates may apply.</label>
                            </td>
                        </tr>
                    </table>
                    <br />
                    <a class="submitButton" href="/#/businessApp/confirmed">
                        CONTINUE
                        <FontAwesomeIcon icon="caret-right" />
                    </a>
                </div>
            </div>
        </div>
    );
}
