import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function ConsumerAppPersonal1() {
    return (
        <div className="kioskContainer">
            <a href="/#/">
                <div className="menuMenuButton">
                    <div>
                        <FontAwesomeIcon icon="caret-left" />
                    </div>
                    <div>
                        <div>Main Menu</div>
                    </div>
                </div>
            </a>
            <div id="logoRight"></div>
            <div className="subContent">
                <div className="consumerAppStepHeader">Step 1: Personal Information (1 of 2)</div>
                <h2 className="smallerHeadline">Explore Your Loan Options</h2>
                <div className="formContainer">
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="first_name" placeholder="First Name" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm" type="text" name="last_name" placeholder="Last Name" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="email_address" placeholder="Email Address" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm" type="text" name="email_address_confirm" placeholder="Confirm Email" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="ssn" placeholder="Social Security Number" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm" type="text" name="mothers_maiden_name" placeholder="Mother's Maiden Name" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <input className="loanForm" type="text" name="birth_date" placeholder="Birth Date" />
                        </div>
                        <div className="col-6">
                            <input className="loanForm" type="text" name="cell_phone" placeholder="Cell Phone" />
                        </div>
                    </div>
                    <br />
                    <br />
                    <table className="disclaimerRow">
                        <tr>
                            <td valign="top">
                                <input className="disclaimerCheckbox" type="checkbox" id="readDisclaimers" />
                            </td>
                            <td valign="top" className="disclaimerContent">
                                <label for="readDisclaimers">I’m interested in receiving information on products and promotions from Liberty and its affiliates via:</label>
                            </td>
                        </tr>
                    </table>
                    <div>
                        <table className="disclaimerRow">
                            <tr>
                                <td valign="top">
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                    <input className="disclaimerCheckbox" type="checkbox" id="readDisclaimers" />
                                </td>
                                <td valign="top" className="disclaimerContent">
                                    <label for="readDisclaimers">Phone&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                </td>
                                <td valign="top">
                                    <input className="disclaimerCheckbox" type="checkbox" id="readDisclaimers" />
                                </td>
                                <td valign="top" className="disclaimerContent">
                                    <label for="readDisclaimers">Email</label>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <table className="disclaimerRow">
                        <tr>
                            <td valign="top">I understand such product and promotional information may be communicated by telephone via an automatic telephone dialing system, including prerecorded messages, and text messages. Standard data and message rates will apply. I understand my consent to receive loan discounts, product and promotional offers via phone and text is not required to enter into any agreement with Liberty or its affiliates. </td>
                        </tr>
                    </table>
                    <br />
                    <a class="submitButton" href="/#/consumerApp/personal2">
                        NEXT
                        <FontAwesomeIcon icon="caret-right" />
                    </a>
                </div>
            </div>
        </div>
    );
}
