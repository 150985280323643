import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function Splash() {
    return (
        <div className="kioskContainer">
            <div id="logo"></div>
            <div id="chatAgent"></div>
            <div className="splashContent">
                <h1>What type of loan</h1>
                <h2>are you interested in?</h2>
                <br></br>
                <br></br>

                <div className="row">
                    <div className="col-3">&nbsp;</div>
                    <div className="col-6">
                        <a href="/#/selectConsumerLoan">
                            <div className="bigButton">
                                <FontAwesomeIcon icon="user" />
                                <br />
                                Consumer Loan
                            </div>
                        </a>
                    </div>
                    <div className="col-3">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-3">&nbsp;</div>
                    <div className="col-6">
                        <a href="/#/businessApp">
                            <div className="bigButton">
                                <FontAwesomeIcon icon="building" />
                                <br />
                                Business Loan
                            </div>
                        </a>
                    </div>
                    <div className="col-3">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-3">&nbsp;</div>
                    <div className="col-6">
                        <a href="https://lightningfastmortgage2.mymortgage-online.com/loan-app/?siteId=3609690885&workFlowId=106473">
                            <div className="bigButton">
                                <FontAwesomeIcon icon="home" />
                                <br />
                                Home Loan
                            </div>
                        </a>
                    </div>
                    <div className="col-3">&nbsp;</div>
                </div>
            </div>
            <div className="splashFooter">
                Already have a loan?
                <br />
                <div className="smallButton gray">Loan Services</div>
            </div>
        </div>
    );
}
