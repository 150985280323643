import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function HomeApp() {
    return (
        <div className="kioskContainer">
            <a href="/#/">
                <div className="menuMenuButton">
                    <div>
                        <FontAwesomeIcon icon="caret-left" />
                    </div>
                    <div>
                        <div>Main Menu</div>
                    </div>
                </div>
            </a>
            <div id="logoRight"></div>
        </div>
    );
}
